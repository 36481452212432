import React from "react";
import { graphql, PageProps } from "gatsby";
import { ProjectByIdQuery } from "codegen";
import { Hero, ImageAndTextBlock } from "components/ACF";
import { Button, Layout, Link, Icon } from "components";

import "./job-single.scss";

interface CPTPageContext {
  id: string;
  title: string;
  cptType: string;
}

const ProjectSingle = (props: PageProps<ProjectByIdQuery, CPTPageContext>): JSX.Element => {
  const { job, wp } = props.data;

  const {
    webAppSettings: {
      webAppSettings: { jobPostingColumns: jobFooterBlock },
    },
  } = wp;

  const { title, content, seo, categories, workTypes, locations, jobsAcf } = job;

  return (
    <Layout pageTitle={title} seo={seo} className="job-template">
      <>
        <Hero
          data={{
            content: `<h1>${title}</h1>`,
            compact: true,
            breadcrumbs: "none",
            image: categories.nodes[0].featuredImage?.image,
          }}
          location={seo.breadcrumbs}
        />

        <div className="layout">
          <div className="job-listing__navigation">
            <ul className="job-listing__navigation__cat">
              {Object.keys(categories.nodes).length > 0 &&
                categories.nodes?.map((category, index) => {
                  if (category.slug === "featured") return;
                  return (
                    <li key={index}>
                      <Link to={`/jobs/${category.slug}/`}>{category.name}</Link>
                    </li>
                  );
                })}
              <span className="divider">|</span>
              <li>{title}</li>
            </ul>
            <Link to="/jobs/">
              <Button colorScheme="tertiary">BACK TO JOBS</Button>
            </Link>
          </div>
          <p className="typography--h2 job-listing__title">{title}</p>
          <div className="job-listing__info">
            {Object.keys(workTypes.nodes).length > 0 &&
              workTypes.nodes?.map((item, index) => {
                return (
                  <span className="job-listing__info__bullet" key={index}>
                    {item.name}
                  </span>
                );
              })}
            {Object.keys(locations.nodes).length > 0 &&
              locations.nodes?.map((item, index) => {
                return (
                  <span className="job-listing__info__bullet" key={index}>
                    Location: {item.name}
                  </span>
                );
              })}
            <span className="job-listing__info__date">
              <Icon name="Calendar" iconSize={1.5} /> Posted on {job.date}
            </span>
          </div>

          <div dangerouslySetInnerHTML={{ __html: content }} />

          {jobsAcf?.applyLink && (
            <a href={jobsAcf?.applyLink} className="job-listing__apply" target="_blank" rel="noreferrer">
              <Button colorScheme="secondary">Apply now</Button>
            </a>
          )}
        </div>
        {jobFooterBlock && jobFooterBlock && (
          <ImageAndTextBlock data={{ style: "peak", columns: jobFooterBlock, backgroundcolour: "TERTIARY" }} />
        )}
      </>
    </Layout>
  );
};

export default ProjectSingle;

export const CPTQueryById = graphql`
  query ProjectById($id: String!) {
    # selecting the current page by id
    job: wpJob(id: { eq: $id }) {
      ...JobFields
    }
    wp {
      webAppSettings {
        webAppSettings {
          jobPostingColumns {
            contenttype
            content
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, placeholder: BLURRED, width: 1080)
                }
              }
            }
            imageaccent
          }
        }
      }
    }
  }
`;
